<template>
  <div class="roster">
    <template v-if="viewType === 'list'">
      <!-- 列表 -->
      <list @changeType="changeType"></list>
    </template>
    <template v-else-if="viewType === 'detail'">
      <!-- 详情 -->
      <detail @changeType="changeType" :id="currentId" :viewStatus="viewStatus"></detail>
    </template>
  </div>
</template>

<script>
import List from "./list";
import Detail from "./detail";
export default {
  name: "Roster",
  components: { Detail, List },
  data() {
    return {
      currentId: "",
      viewType: "list", //list列表 & detail详情
      viewStatus: ""
    };
  },
  methods: {
    changeType(data) {
      this.viewType = data.type;
      this.currentId = data.id;
      this.viewStatus = data.viewStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.roster {
  // background-color: white;
}
</style>